import request from './request.js';

// 预绑卡
export function pre_bind_card(data) {
    return request({
        url: '/user-bank-card/pre-bind-card',
        method: 'post',
        data
    })
}

// 分页查询子计划列表
export function sub_plan_list(data) {
    return request({
        url: '/sub-plan-statistics/list',
        method: 'get',
        params: data
    })
}

// 获得绑定银行信息
export function get_bind_bank(data) {
    return request({
        url: '/user-bank-card/get-bind-bank',
        method: 'get',
        params: data
    })
}

// 获得协议
export function get_agreement(data) {
    return request({
        url: '/user-bank-card/get-agreement',
        method: 'get',
        params: data
    })
}

// 确认绑卡/确认支付
export function confirm_bind_pay(data) {
    return request({
        url: '/user-bank-card/confirm-bind-pay',
        method: 'get',
        params: data
    })
}

// 提交添加绑卡信息
export function add_bank(data) {
    return request({
        url: '/user-bank-card/add-bank',
        method: 'POST',
        data
    })
}


// 发起支付
export function recharge_initiatePay(data) {
    return request({
        url: '/user/recharge/initiatePay',
        method: 'POST',
        data
    })
}

// 微信支付查看订单情况
export function find_order_state(data) {
    return request({
        url: '/user/recharge/find-order-state',
        method: 'POST',
        data
    })
}